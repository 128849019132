<template lang="pug">
    #Certificacoes
        ul
            li(v-for="item in items")
                a(:href="item.url", target="_blank")
                    .img
                        img(:src="item.image").image
                    .icon
                        FontAwesomeIcon(:icon="icon.faDownload").download
</template>
 
<script>
import { props } from '@/mixins/component';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export default {
    name: "certificacoes",
    props,

    data() {
        return {
            icon: {
                faDownload,
            },
            items: this.contents.content.map((item) => {
                return {
                    url: `${process.env.VUE_APP_FILE_API_ROUTE}/files/file/${item.file.id}`,
                    image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
                }
            })
        }
    },
}
</script>

<style lang="stylus" scoped src="./Certificacoes.styl"></style>

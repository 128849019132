<template lang="pug">
    div(:class="contents.content.alignment || 'left'")
        a(:href="file", target="_blank").botao {{ contents.content.name }}
</template>

<script>
import { components, props } from '@/mixins/component';

export default {
    name: "component-botao-com-arquivo",
    props,
    components,
    computed: {
        file() {
            return `${process.env.VUE_APP_FILE_API_ROUTE}/files/file/${this.contents.content.file.id}`
        }
    }
}
</script>

<style lang="stylus" scoped src="./BotaoArquivo.styl"></style>

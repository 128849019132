<template lang="pug">
	section#alimentosForm
		.container.box(v-if="hasBox")
			a(:href="box.link")
				picture
					source(:srcset="box.desktop", media="(min-width: 500px)")
					img(:src="box.mobile", alt="Imagem do Box")
			//- .innerBox
			//- 	.information
			//- 		h3 Quer mais informações sobre nossos produtos?
			//- 		p Acesse a ficha técnica e composição de nossos alimentos em nossa página de produtos
			//- 		button
			//- 			a(:href="box.link") Clique aqui
			//- .text
			//- 	p Você também pode preencher o formulário abaixo e aguardar a resposta da nossa equipe. Em breve a Coamo entrará em contato com você:
		.container.form
			.text
				h2 Entre em contato
				p Após envio do formulário abaixo entraremos em contato para indicar o melhor representante para seu negócio
			form(@submit.prevent="send")
				.input-wrapper._100
					.required *
					.input
						input(type="text", v-model="form.name", placeholder="Nome Completo" required)
				.input-wrapper._100
					.required *
					.input
						input(type="email", v-model="form.email", placeholder="Email" required)
				.input-wrapper._100
					.required *
					.input
						input(type="text", v-model="form.address", placeholder="Endereço" required)
				.group
					.input-wrapper._30
						.required *
						.select
							select(v-model="form.state", @change="getCities" required)
								option(disabled selected :value="null") UF
								option(v-for="state in ufs" :value="state") {{state}}
					.input-wrapper._70
						.select
							select(v-model="form.city" required)
								option(disabled selected :value="null") Cidade
								option(v-for="city in cities" :value="city") {{city}}
				.group
					.input-wrapper._30
						.required *
						.input
							input(type="text", v-model="form.ddd", placeholder="DDD" v-mask="['(##)']" required)
					.input-wrapper._70
						.input
							input(type="text", v-model="form.phone", placeholder="Telefone" v-mask="['#####-####','####-####']" required)

				.input-wrapper._100
					.required *
					.select
						select(v-model="form.subject" required)
							option(disabled selected :value="null") Motivo
							option(v-for="assunto in assuntos" :value="assunto") {{assunto}}
				.input-wrapper._100
					.required *
					.textarea
						textarea(rows="6" placeholder='Mensagem' v-model="form.message" required)
				.input-wrapper._100
					.required
					.row
						.button
							button(type="submit", :disabled="!isValid || sending").squareButton {{ sending ? 'Enviando...' : 'Enviar' }}
				.message(v-if="displayMessage")
					span Enviado com sucesso!
				.message(v-if="errorMessage")
					span Falha ao enviar o formulário.
				.aside-form
					.required *
					.box Preenchimento obrigatório
		.container
			.phoneBox
				.left
					img(src="@images/paginas/fale-conosco/icons/celular.png").ligueCel
				.right
					.text
						p Ou ligue
						span.ligueVerde 0800 0 446161
					.text
						p De segunda a sexta-feira, no período das 08h30 às 11h30 e das 14 às 17h.
		.container
			.options
				h2 Conheça nossos produtos
				ul.optionList
					li(v-for="item, index in options").optionItem
						.img
							img(:src="item.img" :alt="item.text")
						.texts
							span Quero comprar
							br
							strong {{ item.text }}
						button(@click="moveTo(item.type)").squareButton Ver mais
</template>

<script>
import { mask } from 'vue-the-mask'
import { props } from '@/mixins/component'
import Alimenticia from '@images/onde-encontrar/alimenticia.png';
import Representante from '@images/onde-encontrar/representante.png';
import Axios from 'axios'

const axios = Axios.create({
	baseURL: ``,
})

// const urlAlimentos = process.env.VUE_APP_WEB_ALIMENTOS_ROUTE

export default {
    name: "section-alimentos-form",
	directives: { mask },
	props,
	data() {
		return {
			form: { 
				name: '',
				email: '',
				address: '',
				state: null,
				city: null,
				ddd: '',
				phone: '',
				subject: null,
				message: ''
			},
			displayMessage: false,
			errorMessage: false,
			sending: false,
			ufs: [],
			cities: [],
			box: {
				link: '',
				image: ''
			},
			options: [
				{
                    img: Alimenticia,
                    text: 'para mim',
                    type: 'linha-alimenticia',
				},
				{
                    img: Representante,
                    text: 'para meu negócio',
                    type: 'representantes',
                },
			],
		}
	},
	computed: {
		hasBox() {
			if (this.contents.content.box) {
				return this.contents.content.box
			}
			return ''
		},
		assuntos() {
			let newAssuntos = []
			if (this.contents.content.technical) newAssuntos.push('Contato Técnico')
			if (this.contents.content.compliments) newAssuntos.push('Elogios')
			if (this.contents.content.logistics) newAssuntos.push('Logística')
			if (this.contents.content.reports) newAssuntos.push('Solicitação de Laudos')
			if (this.contents.content.suggestions) newAssuntos.push('Sugestões')
			if (this.contents.content.quality) newAssuntos.push('Qualidade de produtos')

			return newAssuntos
		},
		isValid() {
			return (
				this.form.name &&
				this.form.email &&
				this.form.address &&
				this.form.state &&
				this.form.city &&
				this.form.ddd &&
				this.form.phone &&
				this.form.subject &&
				this.form.message
			)
		},
		settings() {
            return this.$store.state.settings
        },
	},
	watch: {
        settings: function () {
			const alimentosSetting = this.settings.find(setting => setting.attribute === 'alimentosApi')
			axios.baseURL = `${alimentosSetting.value}`
        }
	},
	mounted() {
		this.getUfs()
		if (this.contents?.content?.box) {
			if (this.contents?.content?.desktop) {
				this.box.desktop = this.contents.content.desktop
				this.box.mobile = this.contents.content.mobile
			}
			if (this.contents?.content?.link) {
				this.box.link = this.contents.content.link
			}
		}
	},
	created() {
		this.$store.dispatch('fetchSettings')
	},
	methods: {
		moveTo(type) {
			const alimentosSetting = this.settings.find(setting => setting.attribute === 'alimentos')
			const route = `${alimentosSetting.value}/onde-encontrar?active=${type}`
			window.open(route, '_blank')
		},
		send() {
			this.sending = true
			this.errorMessage = false
			this.displayMessage = false
			axios.post(`contacts`, {
                name: this.form.name,
                email: this.form.email,
                address: this.form.address,
                state: this.form.state,
                city: this.form.city,
                phone: `(${this.form.ddd}) ${this.form.phone}`,
                subject: this.form.subject,
                message: this.form.message,
            }).then(() => {
                this.displayMessage = true
                // this.form = { ...FORM }
            }).catch(() => {
                this.errorMessage = true
            }).finally(() => {
                setTimeout(() => {
                    this.sending = false
                    this.displayMessage = false
                    this.errorMessage = false
                }, 4000)
            })
		},
		getUfs() {
			this.$axios
				.get('ibge-api')
				.then(response => this.ufs = response.data)
		},
		getCities() {
            this.$axios
				.get(`ibge-api/${this.form['Estado']}`)
                .then(response => {
                    this.cities = response.data.sort((a, b) => 
                        a.localeCompare(b, 'pt', { sensitivity: 'base' })
                    );
                });
        },
		changeInputType(fieldName) {
			this.$set(this.form, fieldName, '')
			this.$nextTick(() => {
				this.$refs[fieldName].type = 'date'
				this.$refs[fieldName].focus()
			});
		},
	},
}
</script>

<style lang="stylus" scoped src="./AlimentosForm.styl"></style>
